import React from 'react';
import './Intro.scss';
import logo from '../../logo.svg';

const Intro: React.FC = () => (
  <div className="intro" data-testid="Intro">
    <img src={logo} className="main-logo" alt=""/>
    <div className="claim">
      Make party everywhere!
    </div>
  </div>
);

export default Intro;
