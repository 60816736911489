import React from 'react';
import ReactDOM from 'react-dom';
import './main.scss';
import App from './App';
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <div className="container">
      <App />
    </div>
    <footer className="main-footer">
      <strong>Strobox</strong>&nbsp;Frydrych&sons 2020 -&nbsp;<a href="mailto: jakub.frydrych@gmail.com">Contact us</a>
    </footer>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
