import firebase from 'firebase/app';
import 'firebase/database';

const config = {
  apiKey: "AIzaSyBuLtPablfQgOKGy9QTr9savcHNEPEkpnQ",
  authDomain: "strobox-d61a5.firebaseapp.com",
  databaseURL: "https://strobox-d61a5.firebaseio.com",
  projectId: "strobox-d61a5",
  storageBucket: "strobox-d61a5.appspot.com",
  messagingSenderId: "292308210081",
  appId: "1:292308210081:web:570222d2497cea8646fbe0",
  measurementId: "G-EZ10HPGYNE"
};
 
class Firebase {
  db: any;

  constructor() {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }
    this.db = firebase.database();
  }

  scene = ( sceneId: string ) => this.db.ref(`scenes/${sceneId}`);
  scenes = () => this.db.ref('scenes');
  users = () => this.db.ref('users');
  user = ( uid: string ) => this.db.ref(`users/${uid}`);
}
 
export default Firebase;