import React from 'react';
import logo from '../../logo.svg';
import './MainHeader.scss';

interface IProps {
  actualScreen: ('home' | 'editor' | 'scene');
  toHomeScreen: () => void;
  sceneId: null | string;
}

const MainHeader: React.SFC<IProps> = (props: IProps) => (
  <header className={`main-header ${(props.actualScreen === 'home' ? 'home' : 'split')}`} data-testid="MainHeader">
    {props.actualScreen !== 'home' && 
    <div className="menu">
      <button className="circle-button home-icon" onClick={() => {props.toHomeScreen()}}>Home</button>
    </div>}

    {props.actualScreen !== 'scene' && 
      <div className="logo">
        <img src={logo} className="main-logo" alt="Strobox logo"/>
      </div>}

    {props.actualScreen === 'scene' &&
      <div className="id-box">
        Scene ID <div className="id">{props.sceneId}</div>
      </div>}
  </header>
);

export default MainHeader;
