import React, { Component } from 'react';
import './Editor.scss';

interface IEditorProps {
  createScene: (rythm: number) => void;
}

interface IEditorState {
  sliderValue: number;
}

class Editor extends Component<IEditorProps, IEditorState> {
  constructor(props: IEditorProps) {
    super(props);
    
    this.state = {
      sliderValue: 1000
    }
  }

  /*
  addTap = () => {
    console.log(`[debug] tap`);
    const { taps } = this.state;
    taps.push(111);
    this.setState({taps});
  }

  resetRythm = () => {
    this.setState({taps: []});
  }*/

  sliderChange = (value: number) => {
    this.setState({sliderValue: value});
  }
  
  render() {
    const { sliderValue } = this.state;
    const showWarning = false;
    return (
      <div className="Editor" data-testid="Editor">
         <div className="editor-box">
         {showWarning && <div className="warning info">
            <div className="title">Warning!</div>
            <p> Flashing effect in susceptible people may trigger an attack of epilepsy</p>
            <p>The creator is not responsible for any damage to the device.</p>
            <div className="submit-box">
              <button>I agree</button>
            </div>
          </div>}
        
        
          <div className="preview">
            <div className="white-box" style={{animationDuration: `${sliderValue}ms`}} />
            <div className="title">
              Preview rythm
            </div>
          </div>

          <div className="slider-box">
            <div className="actual">{sliderValue}ms</div>
            <div className="slider">
              <div className="max">200ms</div>
                <input 
                  type="range" min="200" max="2000" 
                  value={sliderValue}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>,): void => {
                    this.sliderChange(parseInt(ev.target.value));
                }}
                  />
              <div className="min">2000ms</div>
            </div>
          </div>

          <div className="submit-scene">
            <button onClick={() => {this.props.createScene(sliderValue)}}>Create scene</button>
          </div>

        </div>

      </div>
    );
  }
}

export default Editor;

/*
div className="editor-box">
          <div className="types">
            <button className="active">Rythm</button>
            <button>Timeline</button>
          </div>
          <div className="preview">
            <div className="rythm-preview">
              <div className="time">{taps[0] && `${taps[0]}ms`}</div>
              <div className="time">{taps[1] && `${taps[1]}ms`}</div>
              <div className="time">{taps[2] && `${taps[2]}ms`}</div>
              <div className="time">{taps[3] && `${taps[3]}ms`}</div>
              <div className="time">{taps[4] && `${taps[4]}ms`}</div>
              <div className="time">{taps[5] && `${taps[5]}ms`}</div>
              <div className="time">{taps[6] && `${taps[6]}ms`}</div>
              <div className="time">{taps[7] && `${taps[7]}ms`}</div>
              <div className="time">{taps[8] && `${taps[8]}ms`}</div>
              <div className="time">{taps[9] && `${taps[9]}ms`}</div>
            </div>
          </div>
        
          <div className="touch-box">
            <Hammer onTap={this.addTap}>
              <div className="toucher">
                <span>Here make your rythm</span>
              </div>
            </Hammer>
          </div>

          <div className="tools">
            <button>Try</button>
            <button onClick={this.resetRythm}>Reset</button>
          </div>

          <div className="create-scene">
            <button>Create scene room</button>
          </div>
        </div>*/