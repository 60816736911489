import React, { Component } from 'react';
import { uuid } from 'uuidv4';
import './App.scss';
import Intro from './components/Intro/Intro';
import Home from './components/Home/Home';
import Editor from './components/Editor/Editor';
import Scene from './components/Scene/Scene';
import MainHeader from './components/MainHeader/MainHeader';
import Firebase from './components/Firebase/Firebase';


type ScreensTypes = ('home' | 'editor' | 'scene');

type SceneStatuses = ('open' | 'lock' | 'closed');

interface ISceneDataFirebase {
  rythm: number;
  status: SceneStatuses;
  uid: string;
}

interface ISceneData {
    sceneId: string;
    owner: boolean;
    rythm: number;
}

interface IProps {};

interface IState {
  intro: boolean;
  isActive: boolean;
  actualScreen: ScreensTypes;
  sceneId: string | null;
  sceneData: null | ISceneData;
  sceneError: null | { type: string, message: string };
}

class App extends Component<IProps, IState> {
  firebase = new Firebase();
  userId: string;

  constructor(props: IProps) {
    super(props);

    this.userId = localStorage.getItem('uid') || this.generateUserId();

    this.state = {
      intro: true,
      isActive: false,
      actualScreen: 'home',
      sceneId: null,
      sceneData: null,
      sceneError: null,
    }
  }

  generateUserId() {
    const uid = uuid()
    localStorage.setItem('uid', uid);
    this.firebase.user(uid).set({
      timestamp: Date.now(),
    });
    return uid;
  }
  
  componentDidMount() {
    

    setTimeout(() => {
      this.setState({intro: false});
    }, 4000);
  }

  toHomeScreen = () => {
    this.setState({actualScreen: 'home'});
  }

  changeScreen = (screen: ScreensTypes) => {
    this.setState({actualScreen: screen});
  }

  changeSceneStatus = (status: SceneStatuses) => {
    const { sceneId } = this.state;

    if(sceneId) {
      this.firebase.scene(sceneId).update({status});
    }
  }

  generateScreenId() {
    const chars = [0,1,2,3,4,5,6,7,8,9];
    let sceneId = '';

    for (let i = 0; i < 5; i++) {
      const charIndex = Math.floor(Math.random() * Math.floor(chars.length - 1));
      sceneId += chars[charIndex];
    }
    
    return sceneId;
  }

  createScene = (rythm: number) => {
    const sceneId = this.generateScreenId();
    this.firebase.scene(sceneId).set({
      timestamp: Date.now(),
      uid: this.userId,
      rythm,
      status: 'open',
    });
    this.setState({sceneId});
    this.loadScene(sceneId);
  }

  loadScene = (sceneId: string) => {
    this.firebase.scene(sceneId).once("value", (snapshot: any) => {
      if (snapshot && snapshot.exists()) {
        const resultSceneData: ISceneDataFirebase = snapshot.val();
        console.log(`[sceneData]`, resultSceneData);

        if(resultSceneData.status === 'lock') {
          this.setSceneError('warn', 'Scene locked, please wait for owner pause scene for unlock');
          return;
        } else if(resultSceneData.status === 'closed') {
          this.setSceneError('warn', 'Scene has already been locked and will be deleted the next time');
          return;
        } else {
          this.setState({ 
            sceneData: {
              sceneId: sceneId,
              owner: (this.userId === resultSceneData.uid),
              rythm: resultSceneData.rythm
            },
            sceneId,
            actualScreen: 'scene'
          });
        }
      } else {
        this.setSceneError('error', 'Sorry SceneID not exist');
      }
    });
  }

  setSceneError = (type: ('error' | 'warn'), message: string) => {
    this.setState({sceneError: {
      type,
      message
    }});
  }

  render() {
    const { intro, actualScreen, sceneId, sceneError, sceneData } = this.state;

    if(intro) {
      return <Intro />
    }

    return (
      <div className="App">
        <MainHeader sceneId={sceneId} actualScreen={actualScreen} toHomeScreen={this.toHomeScreen} />
        {actualScreen === 'home' && <Home changeScreen={this.changeScreen} loadScene={this.loadScene} sceneError={sceneError} />}
        {actualScreen === 'editor' && <Editor createScene={this.createScene} />}
        {actualScreen === 'scene' && sceneData && <Scene sceneData={sceneData} changeSceneStatus={this.changeSceneStatus} userId={this.userId} />}
      </div>
    );
  }
}

export default App;
