import React from 'react';
import Pusher from 'pusher-js';
import logo from '../../logo.svg';
import './Scene.scss';

type SceneStatuses = ('open' | 'lock' | 'closed');

interface ISceneData {
  sceneId: string;
  owner: boolean;
  rythm: number;
}

interface ISceneProps {
  userId: string;
  sceneData: ISceneData;
  changeSceneStatus: (status: SceneStatuses) => void;
}

interface ISceneState {
  isActive: boolean;
}

class Scene extends React.Component<ISceneProps, ISceneState> {
  channelSubscibe: any;
  pusher: any;
  channel: any;
  channelControll: boolean = false;
  sceneConsuments: number = 0;

  constructor(props: ISceneProps) {
    super(props);
    this.pusher = new Pusher('c9d38140efc276d5011c', {
      cluster: 'eu',
      authEndpoint: `https://strobox.frydrychs.com/presence_auth/?uid=${props.userId}&screen=${window.screen.availWidth}x${window.screen.availHeight}`,
    });
    this.state = {
      isActive: false,
    }
  }

  componentDidMount() {
    const { sceneData } = this.props;
    this.channel = this.pusher.subscribe(`presence-scene-${sceneData.sceneId}`);

    this.channel.bind('pusher:subscription_succeeded', () => {
      this.channelControll = true;
      this.sceneConsuments = this.channel.members.count;
    });

    this.channel.bind('pusher:member_added', () => {
      this.sceneConsuments += 1;
    });

    this.channel.bind('pusher:member_removed', () => {
      this.sceneConsuments -= 1;
    });

    this.channel.bind('pusher:subscription_error', (err: any) => {
      console.log(`[subscription_error] `, err);
    });

    this.channel.bind('client-status-change', (data: { active: boolean }) => {
      console.log('incomming message', data);
      this.setState({isActive: data.active});
    });
  }

  componentWillUnmount() {
    const { sceneData } = this.props;
    this.pusher.unsubscribe(`presence-scene-${sceneData.sceneId}`);
    this.channel = null;
  }

  requestFullscreen(enter: boolean) {
    if(!document.body.requestFullscreen) return;

    if(enter) {
      document.body.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  sendRun = () => {
    const { changeSceneStatus } = this.props;
    const triggered = this.channel.trigger('client-status-change', { 'active': true });
    this.requestFullscreen(true);
    if(triggered) {
      window.setTimeout(() => {
        this.setState({isActive: true});
        changeSceneStatus('lock');
      }, 10);
    }
  }

  sendPause = () => {
    const { changeSceneStatus } = this.props;
    const triggered = this.channel.trigger('client-status-change', { 'active': false });
    this.requestFullscreen(false);
    if(triggered) {
      this.setState({isActive: false});
      changeSceneStatus('open');
    }
  }

  render() {
    const { sceneData } = this.props;
    const { isActive } = this.state;

    return (
      <div className="Scene">
        {isActive && <div className="white-box" style={{animationDuration: `${sceneData.rythm}ms`}} ></div>}
        <div className="options">
          <div className={`epilepsy-warning ${(isActive ? 'hide' : '')}`}>
            <div className="title">Warning</div>
            <p>Scene contains flashing lights which may not be suitanable for pohotesensitive epilepsy</p>
          </div>
          <div className="logo-box">
            <img src={logo} className="main-logo" alt=""/>
          </div>
          <div className="controls-box">
            <div className="connected-display">
              Scene displays: {this.sceneConsuments}
            </div>
            {sceneData.owner && <div className="controls">
              {isActive && <button className="circle-button pause-icon" onClick={this.sendPause}>Pause</button>}
              {!isActive && <button className="circle-button play-icon" onClick={this.sendRun}>Run</button>}
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

export default Scene;
