import React from 'react';
import './Home.scss';

type ScreensTypes = ('home' | 'editor' | 'scene');

interface IHomeProps {
  changeScreen: (screen: ScreensTypes) => void;
  loadScene: (sceneId: string) => void;
  sceneError: null | { type: string, message: string }
}

interface IHomeState {
  sceneId: string;
}

class Home extends React.Component<IHomeProps, IHomeState> {
  constructor(props: IHomeProps) {
    super(props);

    this.state = {
      sceneId: '',
    }
  }

  onChangeSceneId = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({sceneId: e.target.value});
  }

  onLoadScene = () => {
    const { loadScene } = this.props;
    const { sceneId } = this.state;

    loadScene(sceneId);
  }

  render() {
    const { changeScreen, sceneError } = this.props;
    const { sceneId } = this.state;

    return (
      <div className="Home" data-testid="Home">
        <div className="options">
          <button className="scene-maker-button" onClick={() => {changeScreen('editor')}}>
            <div className="icon icon-editor"></div>
            <div className="title">Scene maker</div>
          </button>
          <div className="join-scene">
            <label htmlFor="scene-id">Scene ID</label>
            <input type="text" id="scene-id" placeholder="#code" onChange={this.onChangeSceneId} value={sceneId} />
            <button onClick={this.onLoadScene}>Join</button>
          </div>
          {sceneError && <div className={`message-box ${sceneError.type}`}>
            {sceneError.message}
          </div>}
        </div>
        <div className="donate-box">
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="35R79KWV8G43Y" />
          <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
          <img alt="" src="https://www.paypal.com/en_CZ/i/scr/pixel.gif" width="1" height="1" />
          </form>
        </div>
      </div>);
  }
}

export default Home;
